import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/UrlContants";
import { UrlBase } from "../../utils/Urls";
import { Console } from "console";
import { serialize } from 'object-to-formdata';

// Opciones para la serialización
const options = {
  indices: true,
  dotsForObjectNotation: true,
};

export const getRequestUser = async (page: any, filter: any, company: any) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(
      URL.REQUEST_USER_LIST(page, UrlBase.registration_x_page, company),
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);

    });
  return responseData;
};
//REQUEST_USER_LIST_USERS_NO_BIKER
export const getRequestUser2 = async (page: number, filter: string) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(URL.REQUEST_USER_LIST2(page, UrlBase.registration_x_page), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getRequestUserNoBiker = async (page: number, filter: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_USER_LIST_USERS_NO_BIKER(page, UrlBase.registration_x_page,filter))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getRequestDriver = async (page: number, filter: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_DRIVERS_LIST(page, UrlBase.registration_x_page, filter))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getRequestALLDrivers = async (page: number, filter: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_DRIVERS_LIST(page, "10000", filter))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};


export const findByIdRequest = async (id: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_USER(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createUser = async (data: any) => {
  let responseData = {};
  const requestBody = {
    email: data.email,
    identification: data.identification,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    roleID: data.roleID?.id,
    status: data.status,
    password: data.password,
    company: data.idCompany?.id && data.idCompany.id !== 0 ? { id: data.idCompany.id } : {id: 10},
    userCreated: data.userCreated,
    createdAt: data.createdAt

  };

  // Convertir requestBody a FormData
  const formData = serialize(requestBody, options);

  // Añadir la imagen al FormData
  if (data.deliveryCourierImage) {
    formData.append('deliveryCourierImage', data.deliveryCourierImage);
  }

  await privateFetch
    .post(URL.REQUEST_USER_CREATE, formData, {
      headers: {
        ...CONSTANT.HEADER_TYPE_DOCUMENTS,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
      console.log(error)
    });
  return responseData;
};

export const createDriver = async (data: any) => {
  console.log(data)
  let responseData = {};

  const requestBody = {
    createdAt: data.createdAt,
    email: data.email,
    identification: data.identification,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    roleID: "3",
    status: "",
    password: data.password,
    licenceLevel: data.licenseLevel,
    licenseNumber: data.numberLicense,
    accountNumber: data.accountNumber,
    contractType: data.agreement,
    bank: {
      id: parseInt(data.bank.id, 10),
      name: data.bank.name,
    },
    company:
      data.idCompany?.id && data.idCompany.id !== 0
        ? { id: data.idCompany.id }
        : null,
    userCreated: data.userCreated,
    carriages: data.carriage.map((car: any) => ({
      id: car.id ? car.id : null,
      plate: car.plate,
      carriageBrand: { id: car.carriageBrand.id, name: car.carriageBrand.name },
      meanOfTransport: { id: car.meanOfTransport.id, name: car.meanOfTransport.name },
      year: car.year,
      color: { id: car.color.id, name: car.color.name },
    })),

  };

  // Convertir requestBody a FormData
  const formData = serialize(requestBody, options);

  if (data.deliveryCourierImage instanceof File) {
    formData.append('deliveryCourierImage', data.deliveryCourierImage);
  }

  await privateFetch
    .post(URL.REQUEST_USER_CREATE, formData, {
      headers: {
        ...CONSTANT.HEADER_TYPE_DOCUMENTS,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
      console.log(error)
    });
  return responseData;
};

export const updateUser = async (data: any) => {
  console.log(data)
  let responseData = {};
  const requestBody = {
    id: data.id,
    username: data.username,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    status: data.status,
    roleID: data.roleID?.id,
    password: data.password,
    company: data.idCompany?.id && data.idCompany.id !== 0 ? { id: data.idCompany.id } : {id: 10},
    companyRelation: data.companyRelation,
    userModified: data.modifierUser
  };

  const formData = serialize(requestBody, options);

  // Añadir la imagen al FormData
  if (data.deliveryCourierImage) {
    formData.append('deliveryCourierImage', data.deliveryCourierImage);
  }

  await privateFetch
    .put(URL.REQUEST_USER(null), formData, {
      headers: {
        ...CONSTANT.HEADER_TYPE_DOCUMENTS,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_update_error);
      //console.log(error)
    });
  return responseData;
};

export const updateDriver = async (data: any) => {
  console.log(data);
  let responseData = {};

  // Construcción del requestBody
  const requestBody = {
    id: data.id,
    email: data.email,
    identification: data.identification,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    roleID: "3",
    status: "",
    password: data.password,
    licenceLevel: data.licenseLevel,
    licenseNumber: data.numberLicense,
    accountNumber: data.accountNumber,
    contractType: data.agreement,
    bank: {
      id: parseInt(data.bank.id, 10),
      name: data.bank.name,
    },
    company:
      data.idCompany?.id && data.idCompany.id !== 0
        ? { id: data.idCompany.id }
        : null,
    userCreated: data.userCreated,
    carriages: data.carriage.map((car: any) => ({
      id: car.id ? car.id : null,
      plate: car.plate,
      carriageBrand: { id: car.carriageBrand.id, name: car.carriageBrand.name },
      meanOfTransport: { id: car.meanOfTransport.id, name: car.meanOfTransport.name },
      year: car.year,
      color: { id: car.color.id, name: car.color.name },
    })),
    userModified: data.modifierUser,
  };


  // Convertir requestBody a FormData
  const formData = serialize(requestBody, options);

  // Añadir la imagen al FormData
  if (data.deliveryCourierImage instanceof File) {
    formData.append('deliveryCourierImage', data.deliveryCourierImage);
  }
  await privateFetch
    .put(URL.REQUEST_USER(null), formData, {
      headers: {
        ...CONSTANT.HEADER_TYPE_DOCUMENTS,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_update_error);
      //console.log(error)
    });
  return responseData;
};

export const updatePassRequest = async (data: any) => {
  let responseData = {};
  console.log(data)
  const requestBody = {
    id: data.id,
    password: data.password
  };
  await privateFetch
    .put(URL.REQUEST_USER_PASS, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_update_error);
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_USER(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_user_delete_error
      );
    });
  return responseData;
};

export const activateUser = async (code: string, username: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ACTIVATE_USER(code, username), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error activando usuario");
    });
  return responseData;
};

export const valideAvailableUser = async (username: string) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_VALIDATE_AVAILABLE_USER(username), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    });

    console.log(response);

    const isAvailable = response.data && response.data.exists === true;

    return isAvailable;
  } catch (error) {
    console.log(error);
    throw new Error("Error con el usuario");
  }
};

export const valideAvailablePassword = async (userId: number, password: string) => {
  try {
    const requestBody = {
      newPassword: password.toString(),
    };

    const response = await privateFetch.post(URL.REQUEST_VALIDATE_AVAILABLE_PASSWORD(userId), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    });

    console.log(response);

    const isAvailable = response.data && response.data.matches === true;

    console.log(isAvailable);

    return isAvailable;
  } catch (error) {
    console.log(error);
    throw new Error("Error con el usuario");
  }
};